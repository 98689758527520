import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'ViewHome',  // 首页
    component: () => import('../views/ViewHome.vue'),
  },
  {
    path: '/ViewBriefIntroduction',
    name: 'ViewBriefIntroduction', // 集团简介
    component: () => import('../views/ViewBriefIntroduction.vue')
  },
  {
    path: '/ViewAnnouncement',
    name: 'ViewAnnouncement',  // 招聘公告
    component: () => import('../views/ViewAnnouncement.vue')
  },
  {
    path: '/ViewPositionData',
    name: 'ViewPositionData',  // 职位信息
    component: () => import('../views/ViewPositionData.vue'),
    children: [
      // 招聘信息
      { path: 'ComRecruitmentinformationInfo', name: 'ComRecruitmentinformationInfo', component: () => import('../views/components/ComRecruitmentinformationInfo.vue') },
      // 职位详情
      { path: 'ComRecruitmentinformationDetail/:id', name: 'ComRecruitmentinformationDetail', props: true, component: () => import('../views/components/ComRecruitmentinformationDetail.vue') },
      // 搜索结果页面
      { path: 'ComRecruitmentinformationSearch', name: 'ComRecruitmentinformationSearch', props: true, component: () => import('../views/components/ComRecruitmentinformationSearch.vue') },

    ],
  },
  {
    path: '/ViewForm',
    name: 'ViewForm',
    component: () => import('../views/ViewForm.vue'),
    children: [
      // 简历表单
      { path: 'ComFormResume', name: 'ComFormResume', component: () => import('../views/components/ComFormResume.vue') },
      // { path: 'ComFormResume', name: 'ComFormResume', component: () => import('../views/FormResumeNew/ComFormResumeNew.vue') },
      // 账号设置
      { path: 'ComFormSet', name: 'ComFormSet', component: () => import('../views/components/ComFormSet.vue') },

    ],
  },
  {
    path: '/ViewInfo',
    name: 'ViewInfo',  // 简介/公告页面
    component: () => import('../views/ViewInfo.vue')
  },
  {
    path: '/ViewLogin',
    name: 'ViewLogin',  // 登录页
    component: () => import('../views/ViewLogin.vue')
  },
  {
    path: '/privacy',
    name: 'privacyAgreement',  // 隐私协议
    component: () => import('../views/privacyAgreement.vue')
  },

]

// 防止连续点击多次路由报错
let routerPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return routerPush.call(this, location).catch(err => err)
}

const scrollBehavior = function scrollBehavior(to, from, savedPosition) {
  if (savedPosition) {
    return savedPosition
  } else {
    return { x: 0, y: 0 }
  }
};


const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior,
})

export default router
