import router from './router'
import store from './store'
import { Message } from 'element-ui'
import NProgress from 'nprogress'
import 'nprogress/nprogress.css'// nprogress样式文件
import { getToken } from './utils/atuh'

NProgress.configure({ showSpinner: false })

// 需要登录的路径
// const arr = ['/ViewPositionData/ComRecruitmentinformationInfo', '/ViewPositionData/ComRecruitmentinformationDetail']

//当路由开始跳转时
router.beforeEach((to, from, next) => {
  // 开启进度条
  NProgress.start();

  // console.log('到 -', to.path, '从 -', from.path);
  // console.log(arr.includes(to.path));
  // if (arr.includes(to.path)) {
  //   // - 需要登录
  //   if (!getToken()) {
  //     Message({ type: 'warning', message: '请先登录' })
  //     next({ name: 'ViewLogin' })
  //     NProgress.done()
  //   } else {
  //     next();
  //   }
  //   return
  // }

  // - 不需要登录
  next();

});

//当路由跳转结束后
router.afterEach(() => {
  // 关闭进度条
  NProgress.done()
})
