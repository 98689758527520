import Vue from 'vue'
import Vuex from 'vuex'
import { Notification, MessageBox, Message, Loading } from 'element-ui'
import router from '../router/index'
import { setToken, getToken, removeToken } from '../utils/atuh'
import { checkCode, getUser, login } from '@/api/login'
import axios from 'axios'

try {
} catch (error) {

}

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    isLogin: getToken() ? true : false,  // 是否已登陆
    token: getToken() || '',  // token
    userInfo: {}, // 用户信息
    refresh: false
  },
  getters: {
  },
  mutations: {
    SET_isLogin(state, val) {
      state.isLogin = val
    },
    SET_token(state, val = '') {
      state.token = val
    },
    SET_userInfo(state, val = '') {
      state.userInfo = val
    },
    SET_refresh(state, val = '') {
      state.refresh = val
    },

  },
  actions: {
    LOGOUT({ commit }) {
      // 退出登录
      commit('SET_isLogin', false)
      removeToken()
      commit('SET_token', '')
      Message({ message: '已退出登录', type: "success" })
      router.push({ name: 'ViewHome' })
    },
    async GET_TOKEN({ commit, dispatch }, val) {
      // 手机号登录
      try {
        const res = await checkCode(val)

        let token = res.data

        setToken(token)

        commit('SET_token', token)
        commit('SET_isLogin', true)
        Message({ message: '登录成功', type: 'success' })
        dispatch('GET_INFO')

        setTimeout(() => {
          // 跳转到简历页面
          router.push({ name: 'ComFormResume' })
        }, 500);
      } catch (error) {
        console.log(error);
      }

    },
    async GET_TOKENZ({ commit, dispatch }, val) {
      // 账号登录
      try {
        const res = await login(val)

        let token = res.data

        setToken(token)

        commit('SET_token', token)
        commit('SET_isLogin', true)
        Message({ message: '登录成功', type: 'success' })
        dispatch('GET_INFO')

        // setTimeout(() => {
        //   // 跳转到简历页面
        //   router.push({ name: 'ComFormResume' })
        // }, 500);
        router.push('/ViewForm/ComFormResume')
      } catch (error) {
        console.log(error);
      }

    },
    async GET_WEIXINTOKEN({ commit, dispatch }, code) {
      // 通过code获取access_token
      try {
        // const res = await weiXinLogin(params)
        const res = await axios({
          url: `/sns/oauth2/access_token?appid=wx20210bab14da0a5a&secret=8f241622cb8ba0ca4bf0a275f16b4664&code=${code}&grant_type=authorization_code`,
          method: 'get'
        })
        console.log(res.data);

        // setToken(token)

        // commit('SET_token', token)
        // commit('SET_isLogin', true)
        // Message({ message: '登录成功', type: 'success' })
        // dispatch('GET_INFO')

        // setTimeout(() => {
        //   // 跳转到简历页面
        //   router.push({ name: 'ComFormResume' })
        // }, 500);
      } catch (error) {
        console.log(error);
      }

    },
    async GET_INFO({ commit }, val) {
      // 获取用户信息
      try {
        const res = await getUser()
        commit('SET_userInfo', res.data)
      } catch (error) {
        console.log(error);
      }

    }

  },
  modules: {
  }
})
