<template>
  <div class="nav-top" :class="{ 'nav-fixed': fixed }">
    <div class="nav-wrap">
      <div class="logo pb10">
        <a style="display: block" href="https://www.bbwgw.com/">
          <img
            style="width: 100%; height: auto"
            class=""
            src="../assets/img/logo.png"
            alt=""
            srcset=""
          />
        </a>
      </div>
      <div class="navBtn" @click="navBtn = !navBtn">
        <svg
        v-if="!navBtn"
          t="1677569861144"
          class="icon"
          viewBox="0 0 1024 1024"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          p-id="2952"
          width="32"
          height="32"
        >
          <path
            d="M216.615385 295.384615h586.830769c15.753846 0 31.507692-11.815385 31.507692-31.507692s-15.753846-31.507692-31.507692-31.507692H216.615385c-19.692308 0-31.507692 11.815385-31.507693 31.507692s15.753846 31.507692 31.507693 31.507692zM803.446154 480.492308H216.615385c-19.692308 0-31.507692 11.815385-31.507693 31.507692s15.753846 31.507692 31.507693 31.507692h586.830769c15.753846 0 31.507692-11.815385 31.507692-31.507692s-15.753846-31.507692-31.507692-31.507692zM803.446154 724.676923H216.615385c-19.692308 0-31.507692 11.815385-31.507693 31.507692s15.753846 31.507692 31.507693 31.507693h586.830769c15.753846 0 31.507692-11.815385 31.507692-31.507693s-15.753846-31.507692-31.507692-31.507692z"
            fill="#707070"
            p-id="2953"
          ></path>
        </svg>

        <svg v-else t="1677640432495" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="4580" width="32" height="32"><path d="M806.4 263.2l-45.6-45.6L512 467.2 263.2 217.6l-45.6 45.6L467.2 512 217.6 760.8l45.6 45.6L512 557.6l248.8 248.8 45.6-45.6L557.6 512z" p-id="4581" fill="#8a8a8a"></path></svg>
      </div>
      <div class="r" v-show="navBtn">
        <!-- 导航 -->
        <div class="nav-list pb10">
          <div
            class="list-item"
            v-for="(item, index) in NavList"
            :key="item.id"
            @click="handleRouter(item, index)"
          >
            <!-- {{ item.name }} -->
            <router-link :to="{ name: item.path }">{{ item.name }}</router-link>
          </div>
          <div class="list-item" @click="GO()">关于我们</div>
        </div>

        <!-- 用户 -->
        <div class="user">
          <div
            class="info"
            :style="{
              'align-items': $store.state.isLogin ? 'flex-end' : 'center',
            }"
          >
            <div class="head-portrait">
              <!-- 头像 -->
              <img class="touxiang" src="@/assets/img/user.png" alt="" srcset="" />
            </div>
            <div class="login" v-if="!$store.state.isLogin" @click="LOGIN">
              登录
            </div>
            <div class="name" v-else>{{$store.state.userInfo.nickname || '-'}}</div>
          </div>

          <div class="handle" v-if="$store.state.isLogin">
            <div
              class="item"
              v-for="(item, index) in handleArr"
              :key="index"
              @click="handlMenu(item)"
            >
              <img class="user-icon" :src="item.icon" alt="" srcset="" />
              <div>{{ item.name }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
export default {
  props: {
    fixed: {
      // 是否固定在顶部
      type: Boolean,
      require: true,
    },
  },
  data() {
    return {
      navBtn: true,
      active: 0,
      NavList: [
        { id: 1, name: "首页", path: "ViewHome" },
        { id: 2, name: "集团简介", path: "ViewBriefIntroduction" },
        { id: 3, name: "招聘公告", path: "ViewAnnouncement" },
        { id: 4, name: "职位信息", path: "ComRecruitmentinformationInfo" },
        // { id: 5, name: "关于我们", path: "https://www.bbwgw.com/" },
      ],
      handleArr: [
        { icon: require("../assets/iconA/icon-shez.svg"), name: "账号设置" },
        { icon: require("../assets/iconA/icon-jianli.svg"), name: "我的简历" },
        { icon: require("../assets/iconA/icon-out.svg"), name: "退出登录" },
      ],
    };
  },
  created() {
    this.GETWINDOW();
  },
  mounted() {
    window.addEventListener("resize", this.GETWINDOW);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.GETWINDOW);
  },
  methods: {
    ...mapActions(["LOGOUT"]),
    GETWINDOW() {
      // 获取可视宽度
      var w = document.documentElement.clientWidth;
      w <= 1280 ? (this.navBtn = false) : (this.navBtn = true);
    },
    GO() {
      window.open("https://www.bbwgw.com/");
    },
    LOGIN() {
      this.$router.push({ name: "ViewLogin" });
      this.setActive(null);
      var w = document.documentElement.clientWidth;
      if (w <= 1280) this.navBtn = false;
    },
    handleRouter(item, index) {
      this.active = index;
      var w = document.documentElement.clientWidth;
      if (w <= 1280) this.navBtn = false;
    },
    setActive(val) {
      this.active = val;
    },
    handlMenu({ name }) {
      if (name == "退出登录") {
        this.LOGOUT();
        return;
      }
      // 用户菜单
      const obj = {
        账号设置: "ComFormSet",
        我的简历: "ComFormResume",
      };
      this.$router.push({ name: obj[name] });
      this.setActive(null);
    },
  },
};
</script>

<style lang="scss" scoped>
a {
  text-decoration: none;
  padding: 0 12px 4px;
  font-weight: bold;
  border-bottom: 4px solid transparent;
  transition: all 0.3s;
  color: #333;
  &:hover {
    color: rgb(0, 112, 199);
  }

  &.router-link-exact-active {
    color: rgb(0, 112, 199);
    border-bottom: 4px solid rgb(0, 112, 199);
  }
}
.touxiang {
  width: 100%;
  height: auto;
}
.user-icon {
  width: 37px;
  height: 37px;
  margin-right: 10px;
}
.pb10 {
  padding-bottom: 10px;
}
.nav-fixed {
  position: fixed;
  width: 100vw;
  background-color: rgba(251, 251, 251, 0.5) !important;
}
.nav-top {
  z-index: 999;
  transition: background-color 0.3s;
  background-color: rgb(251, 251, 251);
  margin-bottom: 40px;
  box-shadow: 0px 3px 10px rgb(0 0 0 / 35%);
  .nav-wrap {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    // max-width: 1200px;
    max-width: 1500px;
    margin: auto;
    // padding: 10px 0 0;
    .r {
      display: flex;
      align-items: flex-end;
    }
    .logo {
      // width: 154px;
      // height: 45px;
      width: 280px;
      height: 80px;
    }
    .nav-list {
      display: flex;
      align-items: center;
      margin-right: 60px;
      .list-item {
        padding: 0 12px 4px;
        cursor: pointer;
        font-weight: bold;
        border-bottom: 4px solid transparent;
        transition: all 0.3s;
        &:not(:last-child) {
          margin-right: 20px;
        }
        &:hover {
          color: rgb(0, 112, 199);
        }
      }
      .active {
        color: rgb(0, 112, 199);
        border-bottom: 4px solid rgb(0, 112, 199);
      }
    }

    .user {
      min-width: 160px;
      padding: 20px;
      position: relative;
      z-index: 999;
      transition: 0.3s;
      .info {
        display: flex;
        // align-items: center;
        .head-portrait {
          width: 72px;
          height: 72px;
          border-radius: 72px;
          margin-right: 10px;
          overflow: hidden;
          // background-color: #d9d9d9;
        }
        .login {
          cursor: pointer;
          width: 86px;
          line-height: 33px;
          background-color: #1890ff;
          text-align: center;
          border-radius: 4px;
          color: #fff;
        }
      }
      &:hover {
        background-color: #ebecef;
      }
      &:hover .handle {
        // display: block;
        // height: 150px;
        opacity: 1;
        visibility: visible;
        transform: translateY(0);
      }
      .handle {
        transition: 0.3s;
        overflow: hidden;
        // height: 0;
        opacity: 0;
        visibility: hidden;
        // display: none;
        position: absolute;
        left: 0;
        right: 0;
        top: 100%;
        background-color: #fff;
        box-shadow: 0px 3px 10px rgb(0 0 0 / 35%);
        transform: translateY(-10px);

        .item {
          cursor: pointer;
          font-size: 13px;
          display: flex;
          align-items: center;
          padding: 6px 10px;
          &:not(:last-child) {
            border-bottom: 1px solid rgb(217, 217, 217);
          }
          &:hover {
            background-color: rgb(235, 235, 235);
          }
        }
      }
    }
  }
}
.navBtn {
  cursor: pointer;
  display: none;
  padding-right: 20px;
}
@media screen and (max-width: 1280px) {
  .navBtn {
    display: block;
  }
  .nav-wrap {
    position: relative;
    align-items: center !important;
  }
  .nav-wrap .r {
    box-shadow: 0px 3px 10px rgb(0 0 0 / 35%);
    z-index: 9999;
    position: absolute;
    top: 100%;
    right: 0;
    background-color: #fff;
    flex-direction: column;
    align-items: center !important;
    padding-top: 20px;
    & .nav-list {
      flex-direction: column;
      margin-right: 0;
      .list-item {
        margin-right: 0 !important;
        margin-bottom: 20px;
      }
    }
  }
}
@media screen and (max-width: 640px) {
  .logo {
    width: 50% !important;
    display: flex;
    align-items: center;
  }
}
</style>