<template>
  <div id="app">
    <!-- 导航栏 -->
    <ComNavTop ref="ComNavTop" :fixed="fixed" />

    <keep-alive :include="['ViewBriefIntroduction','ViewAnnouncement']">
      <router-view />
    </keep-alive>

    <div class="footer">Copyright &copy; 2022&nbsp;&nbsp;广西北部湾国际港务集团有限公司&nbsp;&nbsp;技术支持：广西北港大数据科技有限公司</div>
  </div>
</template>
<script>
import ComNavTop from "@/components/ComNavTop.vue";
import { getToken } from "@/utils/atuh";
import { mapActions } from "vuex";

export default {
  components: { ComNavTop },
  data() {
    return {
      fixed: true,
    };
  },
  watch: {
    $route: {
      handler: function (val, oldVal) {
        let arr = ["ViewHome", "ViewLogin"]; // 非首页/登录页则固定在顶部
        arr.includes(val.name) ? (this.fixed = true) : (this.fixed = false);
        if (val.name == "ComRecruitmentinformationInfo")
          this.$refs.ComNavTop.setActive(3);
      },
      deep: true,
    },
  },
  created() {
    if (getToken()) {
      // 有token就获取用户信息
      this.GET_INFO();
    }
  },
  methods: {
    ...mapActions(["GET_INFO"]),
  },
};
</script>
<style lang="scss">
.el-message {
  top: 45%!important
}
#app {
  // font-family: Avenir, Helvetica, Arial, sans-serif;
  font-family: "Arial Normal", "Arial", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f5f5f5;
}
#nprogress .bar {
  background: #003282 !important;
}
// ::-webkit-scrollbar {
//   width: 8px;
//   height: 10px;
// }

// ::-webkit-scrollbar-thumb {
//   background-color: rgba(0, 0, 0, 0.3);
//   border-radius: 5px;
// }

// ::-webkit-scrollbar-thumb:horizontal:hover,
// ::-webkit-scrollbar-thumb:vertical:hover {
//   background: #0099ff;
// }

// ::-webkit-scrollbar-track {
//   background-color: #eee;
// }


/* webkit浏览器 */
::-webkit-scrollbar {
  width: 8px;
}

::-webkit-scrollbar-track {
  background-color: #f5f5f5;
}

::-webkit-scrollbar-thumb {
  background-color: #ccc;
  border-radius: 5px;
}

/* firefox浏览器 */
* {
  scrollbar-width: thin;
  scrollbar-color: #ccc #f5f5f5;
}

*::-webkit-scrollbar {
  width: 8px;
}

*::-webkit-scrollbar-track {
  background-color: #f5f5f5;
}

*::-webkit-scrollbar-thumb {
  background-color: #ccc;
  border-radius: 5px;
}

*::-webkit-scrollbar-thumb:hover {
  background-color: #999;
}
</style>
