import request from '../utils/request'

// 账号登录
export function login(data) {
  return request({
    url: '/recruit/fore/login',
    headers: {
      isToken: false  // false - 不需要token，true - 需要token
    },
    method: 'post',
    data: data
  })
}

// 微信扫码登录
export function weiXinLogin(params) {
  return request({
    url: 'https://api.weixin.qq.com/sns/oauth2/access_token',
    headers: {
      isToken: false  // false - 不需要token，true - 需要token
    },
    method: 'get',
    params
  })
}

// 获取验证码
export function captchaImage() {
  return request({
    url: '/recruit/fore/captchaImage',
    headers: {
      isToken: false
    },
    method: 'post',
  })
}
// 校验验证码
export function checkCode(data) {
  return request({
    url: '/recruit/fore/checkCode',
    headers: {
      isToken: false
    },
    method: 'post',
    data
  })
}


//  发送短信验证码 
export function sendCode(phone) {
  return request({
    url: `/recruit/fore/getCode/${phone}`,
    headers: {
      isToken: false
    },
    method: 'get',
  })
}

// 注册
export function registration() {
  return request({
    url: '/recruit/fore/registration',
    headers: {
      isToken: false
    },
    method: 'post',
  })
}


// 获取用户信息
export function getUser() {
  return request({
    url: '/recruit/getUser',
    headers: {
      isToken: true
    },
    method: 'get',
  })
}

// 修改用户名
export function editNickname(data) {
  return request({
    url: '/recruit/editNickname',
    headers: {
      isToken: true
    },
    method: 'post',
    data
  })
}

// 修改手机号
export function editPhone(data) {
  return request({
    url: '/recruit/editPhone',
    headers: {
      isToken: true
    },
    method: 'post',
    data
  })
}

// 修改密码
export function editPasswd(data) {
  return request({
    url: '/recruit/editPasswd',
    headers: {
      isToken: true
    },
    method: 'post',
    data
  })
}